import store from '@/store'
const OAuthUrl = 'https://auth.begind.cn/oauth/authorize'
const OAUTH_URL = 'https://auth.begind.cn/oauth/authorize'
const OAUTH_EXIT_URL = 'https://auth.begind.cn/oauth/exit'
const FORBID_NONCE = 'JM3TSCgOrnLGB7V69qfZ'
const P_INSTANCE = 'blrwsa-award-instance'
const P_ALL = 'blrwsa-all'

const checkPrivileges = (values, privileges) => {
  console.log('检查权限:', { values, privileges })
  // 如果没有设置权限要求，则允许访问
  if (!privileges || privileges.length <= 0) {
    return true
  }
  // 如果用户没有任何权限，则拒绝访问
  if (!values || values.length <= 0) {
    return false
  }
  // 检查用户是否拥有所需权限中的任意一个
  for (const v of values) {
    if (privileges.includes(v)) {
      return true
    }
  }
  return false
}

const checkPrivilegesByPath = (values, path) => {
  const routerPrivilege = store.getters.routerPrivilege
  const privileges = routerPrivilege.get(path)
  return checkPrivileges(values, privileges)
}

export default {
  P_INSTANCE,
  OAuthUrl,
  P_ALL,
  OAUTH_URL,
  OAUTH_EXIT_URL,
  FORBID_NONCE,
  checkPrivileges,
  checkPrivilegesByPath
}

<template>
  <!-- 左侧边栏-->
  <el-aside
    style="
      background-color: #304156;
      text-align: left;
      width: auto;
      height: 100%;
      z-index: 1001;
    "
    v-show="sidebarShow"
  >
    <a class="logo" href="#/">
      <span class="logo-title">
        <font-awesome-icon icon="fa-headset" />
        <span v-show="!isCollapse"> 客服管理后台 </span>
      </span>
    </a>
    <el-menu
      background-color="#304156"
      text-color="#BFCBD9"
      active-text-color="#409EFF"
      :router="true"
      :collapse="isCollapse"
      :unique-opened="true"
      :default-active="$route.path"
      class="left-menu-vertical"
    >
      <template v-for="(item, i) in visibleMenuRoutes">
        <el-submenu v-if="item.subMenu" :index="item.path" :key="i">
          <template slot="title">
            <font-awesome-icon :icon="item.icon" />
            <span class="menu-title" slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item
            :index="child.path"
            v-for="(child, i) in item.children"
            :key="i"
            v-show="isShowMenu(child)"
          >
            <template v-if="child.icon">
              <font-awesome-icon :icon="child.icon" />
            </template>
            <span class="menu-title" slot="title">{{ child.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          :index="item.children[0].path"
          :key="i"
          v-show="
            item.children[0].meta?.privileges
              ? checkPrivileges(resValues, item.children[0].meta.privileges)
              : true
          "
        >
          <font-awesome-icon :icon="item.children[0].icon" />
          <span class="menu-title" slot="title">{{
            item.children[0].name
          }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
import p from '@/common/privilege'

export default {
  name: 'AltSidebar',
  computed: {
    device () {
      return this.$store.getters.device
    },
    sidebar () {
      return this.$store.getters.sidebar
    },
    resValues () {
      console.log('当前用户权限:', this.$store.getters.resValues)
      return this.$store.getters.resValues ?? []
    },
    visibleMenuRoutes () {
      const routes = this.$router.options.routes
        .filter((item) => !item.hidden)
        .filter((item) =>
          p.checkPrivileges(this.resValues, item?.meta?.privileges)
        )
      console.log('过滤后的路由:', routes)
      return routes
    },
    isMobile () {
      return this.device === 'mobile'
    },
    isCollapse () {
      return !this.sidebar.opened
    },
    sidebarShow () {
      return !this.isMobile || !this.isCollapse
    }
  },
  methods: {
    checkPrivileges (resValues, privileges) {
      return p.checkPrivileges(resValues, privileges)
    },
    isShowMenu (menu) {
      if (Reflect.has(menu, 'hidden')) return !menu.hidden

      return menu.meta?.privileges ? this.checkPrivileges(this.resValues, menu.meta.privileges) : true
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.scrollable {
  overflow-y: scroll;
}
.el-aside {
  color: #bfcbd9;
  background: #304156;
  border-right: 3px solid #f0f2f5;
}
.el-menu {
  border: none;
}
.el-menu i {
  color: #bfcbd9;
}
.left-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.menu-title {
  margin-left: 10px;
}
.logo {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #304156;
  color: #ffffff;
  border-bottom: 3px solid #4b545c;
  text-decoration: none;
}
.logo-title {
  font-weight: 800;
}
.logo-title span {
  margin: 0 38px 0 2px;
}
</style>

import p from '@/common/privilege'
import router from '@/router'
import store from '@/store'
const whiteList = ['/login'] // 不重定向白名单
router.beforeEach((to, from, next) => {
  if (store.getters.token) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (!store.getters.user) { // 验权
        store.dispatch('GetInfo').then(res => { // 拉取用户信息
          if (p.checkPrivileges(store.getters.resValues, to?.meta?.privileges ?? [])) {
            next()
          } else {
            next('/403')
          }
        })
      } else {
        next()
      }
    }
  } else {
    // if (whiteList.indexOf(to.path) !== -1) {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
})

// router.afterEach(() => {
// })

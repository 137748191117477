import p from '@/common/privilege'
import Layout from '@/components/Layout'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 在当前页面时候再次点击当前页面，会有警告！
// 原因：在路由中添加了相同的路由。
// 解决：重写路由的push方法
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

function load (view) {
  return resolve => require([`@/views/${view}.vue`], resolve)
}

const routes = [
  { path: '/login', component: load('Login'), hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: true,
    children: [{ path: '/home', name: '首页', component: load('Home'), icon: 'home' }]
  },
  {
    path: '/ap',
    component: Layout,
    name: '预约用户',
    icon: 'user-astronaut',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_ALL] },

    children: [
      { path: '/ap/user', name: '预约用户', component: load('appointment/User'), icon: 'person', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/wx/user', name: '微信用户', component: load('appointment/WxUser'), icon: 'user-group', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/wx/user/subscribe', name: '微信订阅', component: load('appointment/WxUserSubscribe'), icon: 'star', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/appt/prize', name: '预约奖励', component: load('appointment/ApptPrize'), icon: 'user-check', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/appt/record', name: '预约记录', component: load('appointment/ApptRecord'), icon: 'rectangle-list', meta: { privileges: [p.P_ALL] } }
    ]
  },
  {
    path: '/blr',
    component: Layout,
    name: '活动信息',
    icon: 'globe',
    subMenu: true,
    children: [
      { path: '/ap/activity/config', name: '活动配置', component: load('appointment/ActivityConfig'), icon: 'dice-four', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/activity/award/record', name: '活动奖品记录', component: load('appointment/ActivityAwardRecord'), icon: 'chart-simple', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/activity/task', name: '活动任务', component: load('appointment/ActivityTask'), icon: 'pen-to-square', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/activity/task/record', name: '活动任务奖励', component: load('appointment/ActivityTaskAward'), icon: 'list', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/award/temple', name: '奖品模板', component: load('appointment/AwardTemple'), icon: 'award', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/award/instance', name: '奖品实例', meta: { privileges: [p.P_INSTANCE] }, component: load('appointment/AwardInstance'), icon: 'snowflake' },
      { path: '/ap/user/activity/award', name: '用户活动奖品', component: load('appointment/UserActivityAward'), icon: 'user-check', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/user/address', name: '用户地址', component: load('appointment/UserAddress'), icon: 'address-book', meta: { privileges: [p.P_ALL] } }
    ]
  },
  {
    path: '/sys',
    component: Layout,
    name: '系统设置',
    icon: 'gear',
    subMenu: true,
    meta: { breadcrumbLinkDisable: true, privileges: [p.P_ALL] },
    children: [
      { path: '/ap/game', name: '游戏配置', component: load('appointment/Game'), icon: 'dice-d6', meta: { privileges: [p.P_ALL] } },
      { path: '/ap/wx/template/msg', name: '微信模板消息', component: load('appointment/WxTemplateMsg'), icon: 'paper-plane', meta: { privileges: [p.P_ALL] } }
    ]
  },
  {
    path: '/empty',
    component: Layout,
    redirect: '/empty',
    hidden: true,
    children: [{ path: '/empty', name: 'Empty', component: load('Empty'), icon: 'home' }]
  },
  // Always leave this last one
  { path: '*', component: load('404'), hidden: true }
]

const router = new VueRouter({
  routes
})

export default router
